@keyframes expand {
  0% {
    transform: scale3d(1,0,1); }
  25% {
    transform: scale3d(1,1.2,1); }
  50% {
    transform: scale3d(1,0.85,1); }
  75% {
    transform: scale3d(1,1.05,1); }
  100% {
    transform: scale3d(1,1,1); } }


.customer-info {
  &.disable {
    position: relative;
    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparentize(#5c6671, .2) url("../img/loader.svg") no-repeat center/contain;
      z-index: 5; } } }

form {
  @include scut-clearfix;
  position: relative;
  padding: scut-em(25 0 55 40);

  @include media-below($sm) {
    padding-bottom: scut-em(240); }

  @include media-below($xs) {
    padding-left: scut-em(15); }

  .hidden {
    display: none !important; }

  h2 {
    padding-bottom: scut-em(15, 26); }

  .payment-title {
    @include scut-absolute(scut-em(-42, 25) n n scut-em(40, 25));
    font-size: scut-em(25);
    font-family: $font-l;
    line-height: 1;
    padding-right: scut-rem(40);
    transition: all $hoverTime ease-in;

    @include media-below($xs) {
      padding-right: scut-em(15, 25);
      left: scut-em(15, 25); } }

  .col-1-2 {
    float: left;
    width: 50%;
    padding-right: scut-em(80);

    @include media-below($lg) {
      padding-right: scut-em(60); }

    @include media-below($sm) {
      width: 100%; }

    @include media-below($xs) {
      padding-right: scut-em(15); } }

  .payment-input {
    position: relative;

    &--wrap {
      @include scut-clearfix; }

    &.half {
      @include media-above($xs) {
        float: left;
        width: 49%;
        margin-right: 2%;
        &:last-child {
          margin-right: 0; }
        span {
          bottom: scut-em(30); }
        input:focus + span {
          bottom: scut-em(8) !important; } } }

    span {
      @include scut-absolute(n n 0 0);
      font-family: $font-l;
      font-size: scut-em(15);
      color: $blue;
      transition: bottom $hoverTime ease-in; }
    i {
      z-index: 1;
      opacity: 0;
      @include scut-absolute(0 0 n n);
      display: inline-block;
      padding: scut-em(0 4 4 4);
      font-style: normal;
      font-family: $font-l;
      background:  rgba($blue, 0.7);
      border-radius: scut-em(6);
      transition: all $hoverTime ease-in; } }

  input:-webkit-autofill {
    background-color: $dark !important;
    color: inherit !important; }

  input:not([type="submit"]):not(.payment-value), .cs-placeholder {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: scut-em(40, 19);
    font-size: scut-em(19);
    font-family: $font-l;
    margin-bottom: scut-em(30, 19);
    background-color: $dark;
    border: none;
    border-bottom: 1px solid $gray;
    outline: none;
    color: inherit;
    &.card-name {
      text-transform: uppercase; }

    &.error {
      border-color: $error !important;
      background-image: url("../img/err_sig.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 30px 30px;

      &::-webkit-input-placeholder {
        color: rgba($error,.85); }
      &::-moz-placeholder {
        color: rgba($error,.85); }
      &:-moz-placeholder {
        color: rgba($error,.85); }
      &:-ms-input-placeholder {
        color: rgba($error,.85); } }

    @include media-below($lg) {
      height: scut-em(33, 19);
      line-height: scut-em(33, 19); }

    &::-webkit-input-placeholder {
      color: rgba($white,.27);
      transition: color $hoverTime ease-in;
      text-transform: none; }
    &::-moz-placeholder {
      color: rgba($white,.27);
      transition: color $hoverTime ease-in;
      text-transform: none; }
    &:-moz-placeholder {
      color: rgba($white,.27);
      transition: color $hoverTime ease-in;
      text-transform: none; }
    &:-ms-input-placeholder {
      color: rgba($white,.27);
      transition: color $hoverTime ease-in;
      text-transform: none; }

    &.valid {
      border-color: $green !important; }

    &:focus {
      border-color: $blue;

      &::-webkit-input-placeholder {
        color: transparent; }
      &::-moz-pltransparentder {
       color: transparent; }
      &:-moz-placeholder {
        color: transparent; }
      &:-ms-input-placeholder {
        color: transparent; }

      + span {
        bottom: scut-em(-24px); }

      ~ i {
        opacity: 1;
        top: scut-em(-25px); } } }

  input[type="submit"] {
    //width: 100% !important
    display: inline-block;
    vertical-align: top;
    padding: 0 scut-em(15, 32);
    @include scut-size(scut-em(314, 32) scut-em(40, 32));
    font-family: $font-l;
    font-size: scut-em(42);
    margin: 0 auto;
    display: block;
    text-align: center;
    color: rgba($white,.74);
    background-color: $green;
    border-radius: 10px;
    box-shadow: 0 0 scut-em(35, 32) 0 rgba(0,0,0,.39);
    cursor: pointer;
    outline: none;
    transition: background-color $hoverTime ease-in;
    &:hover {
      background-color: $gray2; }
    &[disabled] {
      cursor: auto;
      color: transparent;
      background: $gray2 url('../img/loader.svg') no-repeat center / contain; }

    @include media-below($lg) {
      width: auto;
      height: scut-em(33, 25);
      line-height: scut-em(33, 25);
      font-size: scut-em(25);
      display: inline; } }

  .payment-value {
    display: none;
    position: relative;
    width: scut-em(90, 20);
    height: scut-em(33, 20);
    margin-left: scut-em(12, 20);
    font-size: scut-em(20);
    font-family: $font-l;
    border: 1px solid $white;
    color: $white;
    border-radius: scut-em(10, 20);
    box-shadow: 0 0 scut-em(35, 32) 0 rgba(0,0,0,.39);
    input {
      width: 100%;
      height: 100% !important;
      padding: 0 scut-em(5, 20) 0 scut-em(20, 20);
      background-color: transparent !important;
      border: 0 !important;
      margin: 0 !important;
      line-height: normal !important;
      outline: none; }

    &:before {
      content: '$';
      @include scut-absolute(scut-em(3, 20) n n scut-em(6, 20));
      .euro & {
        content: '€'; }
      .gbp & {
        content: '\00A3'; } }

    @include media-below($lg) {
        display: inline-block;
        vertical-align: top; } }

  select {
    display: none;
    background-color: transparent;
    color: inherit; }

  .payment-select {
    @include scut-clearfix;

    &--left {
      float: left;
      width: 45%; }
    &--right {
      float: right;
      width: 45%; }
    &--left, &--right {
      > div {
        position: relative;
        cursor: pointer;
        outline: none; } }

    &--both {
      float: left;
      width: 100%; }
    &--both {
      > div {
        position: relative;
        cursor: pointer;
        outline: none; } }

    .cs-placeholder {
      font-family: $font-l;
      line-height: scut-em(40, 19);
      color: rgba($white,.27);

      &.error {
        color: $error; }

      &.cs-selected {
        color: $white; } }
    .cs-active .cs-placeholder {
        color: rgba($white,.27); }

    .cs-options {
      z-index: 3;
      @include scut-absolute(100% 0 n 0);
      visibility: visible;
      overflow: visible;
      pointer-events: none;
      transform-origin: 50% 0;
      transform: scale3d(1,0,1);
      transition: transform 0.3s ease-in;
      ul {
        height: scut-em(300);
        overflow: scroll;
        padding: scut-em(5 0 0 5);
        background-color: $dark;
        box-shadow: 0 0 scut-em(15) rgba(0,0,0,.3);
        li {
          line-height: 1.8;
          cursor: pointer;
          font-family: $font-l; } } }

    .cs-active {
      .cs-placeholder {
        border-color: $blue; }
      .cs-options {
        pointer-events: auto;
        transform: scale3d(1,1,1); } } } }

