@include scut-reset;

@include scut-font-face("Lato", "../../assets/fonts/lato-v11-latin-regular", $formats: woff woff2 ttf eot svg);
@include scut-font-face("LatoLight", "../../assets/fonts/lato-v11-latin-300", $formats: woff woff2 ttf eot svg);
@include scut-font-face("LatoBold", "../../assets/fonts/lato-v11-latin-700", $formats: woff woff2 ttf eot svg);

body {
  margin: 0;
  padding: 0; }


