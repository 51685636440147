@charset "UTF-8";
/*
* Scut, a collection of Sass utilities
* to ease and improve our implementations of common style-code patterns.
* v1.4.0
* Docs at http://davidtheclark.github.io/scut
*/
/* perfect-scrollbar v0.6.16 */
.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none; }

@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important; } }

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent; }

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9; }

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px; }

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9; }

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px; }

.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
  bottom: 0px;
  /* there must be 'bottom' for ps-scrollbar-x-rail */
  height: 15px; }

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  bottom: 2px;
  /* there must be 'bottom' for ps-scrollbar-x */
  height: 6px; }

.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x, .ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 11px; }

.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
  right: 0;
  /* there must be 'right' for ps-scrollbar-y-rail */
  width: 15px; }

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  right: 2px;
  /* there must be 'right' for ps-scrollbar-y */
  width: 6px; }

.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y, .ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 11px; }

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9; }

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px; }

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9; }

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px; }

.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6; }

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9; }

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999; }

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9; }

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999; }

/**
* Media queries
*/
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  -webkit-font-smoothing: antialiased; }

*, *:before, *:after {
  -webkit-font-smoothing: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: normal;
  margin: 0; }

b {
  font-weight: normal; }

label,
select,
option,
button {
  cursor: pointer; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

button,
[type="button"],
[type="submit"],
[type="reset"] {
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  width: auto;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner {
    padding: 0;
    border: 0; }

p:first-of-type {
  margin-top: 0; }

p:last-of-type {
  margin-bottom: 0; }

img,
video {
  max-width: 100%;
  height: auto; }

figure {
  margin: 0; }

@font-face {
  font-family: "Lato";
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/lato-v11-latin-regular.eot");
  src: url("../../assets/fonts/lato-v11-latin-regular.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/lato-v11-latin-regular.woff2") format("woff2"), url("../../assets/fonts/lato-v11-latin-regular.woff") format("woff"), url("../../assets/fonts/lato-v11-latin-regular.ttf") format("truetype"), url("../../assets/fonts/lato-v11-latin-regular.svg#Lato") format("svg"); }

@font-face {
  font-family: "LatoLight";
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/lato-v11-latin-300.eot");
  src: url("../../assets/fonts/lato-v11-latin-300.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/lato-v11-latin-300.woff2") format("woff2"), url("../../assets/fonts/lato-v11-latin-300.woff") format("woff"), url("../../assets/fonts/lato-v11-latin-300.ttf") format("truetype"), url("../../assets/fonts/lato-v11-latin-300.svg#LatoLight") format("svg"); }

@font-face {
  font-family: "LatoBold";
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/lato-v11-latin-700.eot");
  src: url("../../assets/fonts/lato-v11-latin-700.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/lato-v11-latin-700.woff2") format("woff2"), url("../../assets/fonts/lato-v11-latin-700.woff") format("woff"), url("../../assets/fonts/lato-v11-latin-700.ttf") format("truetype"), url("../../assets/fonts/lato-v11-latin-700.svg#LatoBold") format("svg"); }

body {
  margin: 0;
  padding: 0; }

.payment {
  overflow: hidden;
  font-family: "Lato", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  color: #ffffff; }
  .payment:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (max-width: 399px) {
    .payment {
      font-size: 14px; } }
  .payment h2 {
    font-family: "LatoLight", sans-serif;
    font-size: 1.9375em; }
    @media screen and (max-width: 1279px) {
      .payment h2 {
        font-size: 1.625em; } }
  .payment ul {
    padding: 0;
    margin: 0; }
    .payment ul li {
      list-style-type: none; }
  .payment a {
    text-decoration: none;
    color: inherit;
    outline: none; }
  .payment input::-webkit-input-placeholder {
    color: #ffffff;
    font-family: "LatoLight", sans-serif; }
  .payment input::-moz-placeholder {
    color: #ffffff;
    font-family: "LatoLight", sans-serif; }
  .payment input:-moz-placeholder {
    color: #ffffff;
    font-family: "LatoLight", sans-serif; }
  .payment input:-ms-input-placeholder {
    color: #ffffff;
    font-family: "LatoLight", sans-serif; }
  .payment-main {
    position: relative;
    height: 100vh;
    padding-left: 3.125em;
    background-color: #1a2451;
    overflow: auto; }
    @media screen and (min-width: 1280px) {
      .payment-main {
        width: 75%;
        float: left; } }
    @media screen and (max-width: 767px) {
      .payment-main {
        padding-top: 3.125em; } }
    @media screen and (max-width: 499px) {
      .payment-main {
        padding-top: 6.25em; } }
  .payment-menu {
    position: relative;
    height: 3.125em;
    border-bottom: 1px solid #48535d; }
    @media screen and (max-width: 767px) {
      .payment-menu {
        z-index: 3;
        position: fixed;
        top: 0;
        right: 0;
        left: 3.125em;
        background: #1a2451; } }
    .payment-menu--master {
      width: 3.75em;
      position: absolute;
      top: 0.5em;
      right: 0.625em;
      bottom: 0; }
    .payment-menu--dot1 {
      width: 0.625em;
      height: 0.625em;
      position: absolute;
      top: 1.25em;
      right: 5.3125em;
      bottom: 0;
      background: #ccc;
      border-radius: 50px; }
      @media screen and (max-width: 767px) {
        .payment-menu--dot1 {
          position: absolute;
          top: 1.25em;
          right: 4.6875em;
          bottom: 0; } }
    .payment-menu--visa {
      width: 3.75em;
      position: absolute;
      top: 0.1875em;
      right: 6.875em;
      bottom: 0; }
      @media screen and (max-width: 767px) {
        .payment-menu--visa {
          position: absolute;
          top: 0.1875em;
          right: 5.625em;
          bottom: 0; } }
    .payment-menu--dot2 {
      width: 0.625em;
      height: 0.625em;
      position: absolute;
      top: 1.25em;
      right: 11.5625em;
      bottom: 0;
      background: #ccc;
      border-radius: 50px; }
      @media screen and (max-width: 767px) {
        .payment-menu--dot2 {
          position: absolute;
          top: 1.25em;
          right: 9.6875em;
          bottom: 0; } }
    .payment-menu--ssl {
      width: 3.75em;
      position: absolute;
      top: 0.625em;
      right: 13.125em;
      bottom: 0;
      height: 40px; }
      @media screen and (max-width: 767px) {
        .payment-menu--ssl {
          position: absolute;
          top: 0.625em;
          right: 10.625em;
          bottom: 0; } }
      @media screen and (max-width: 767px) {
        .payment-menu--ssl {
          background-size: 80%; } }
    .payment-menu button {
      position: absolute;
      right: 20%;
      bottom: -1.42857em;
      font-size: 0.8125em;
      padding: 0.69231em 1.53846em;
      text-transform: uppercase;
      background-color: #30cbf8;
      color: #191e26;
      border-radius: 3.07692em;
      box-shadow: 0 0 2.69231em 0 rgba(0, 0, 0, 0.39);
      outline: none;
      transition: all 0.3s ease-in; }
      .payment-menu button:hover, .payment-menu button:focus {
        background-color: #3c8dbc;
        color: #ffffff; }
      @media screen and (max-width: 1279px) {
        .payment-menu button {
          right: 10%; } }
      @media screen and (max-width: 767px) {
        .payment-menu button {
          position: fixed;
          right: auto;
          left: 0.92308em;
          bottom: 0.61538em;
          padding: 0;
          text-indent: -9000px;
          width: 2.125em;
          height: 2.125em;
          border-radius: 50%; }
          .payment-menu button:before {
            content: '';
            position: absolute;
            top: 0.5625em;
            right: 1em;
            width: 0.125em;
            height: 1em;
            background: rgba(255, 255, 255, 0.7); }
          .payment-menu button:after {
            content: '';
            position: absolute;
            top: 1em;
            right: 0.5625em;
            width: 1em;
            height: 0.125em;
            background: rgba(255, 255, 255, 0.7); } }
    .payment-menu ul li {
      display: inline-block;
      border-right: 1px solid #48535d;
      text-transform: uppercase;
      float: left; }
      .payment-menu ul li .lang_sel {
        padding-top: 10px;
        line-height: 30px; }
        .payment-menu ul li .lang_sel img {
          width: 30px; }
    .payment-menu ul a {
      display: block;
      padding: 0 1.78571em;
      font-size: 0.875em;
      line-height: 3.57143em; }
      @media screen and (max-width: 767px) {
        .payment-menu ul a {
          font-size: 0.625em;
          padding: 0 1em;
          line-height: 5em; } }
    @media screen and (max-width: 767px) {
      .payment-menu--wrap {
        position: relative; }
      .payment-menu ul {
        white-space: nowrap;
        height: 50px; } }
  .payment-messages {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-height: 2.42105em;
    padding: 0.52632em 0.78947em;
    margin: 0.94737em 4.21053em 0 2.10526em;
    font-size: 1.1875em;
    font-family: "LatoLight", sans-serif;
    line-height: 1.2;
    border-radius: 1.57895em;
    border: 2px solid transparent; }
    .payment-messages.error {
      background-color: #3c8dbc;
      border-color: #c562ac; }
    .payment-messages.success {
      background-color: #3c8dbc;
      border-color: #00a651; }
    @media screen and (max-width: 1279px) {
      .payment-messages {
        padding: 0.42105em 0.78947em; } }
    @media screen and (max-width: 499px) {
      .payment-messages {
        margin: 0.94737em 0.78947em 0;
        position: fixed;
        bottom: 20px; } }
  .payment-info {
    width: 18.4375em;
    height: 11.5625em;
    box-shadow: 0 0 35px 14px rgba(0, 0, 0, 0.3);
    margin: 1.875em 0em 0em 0.625em;
    border-radius: 0.625em; }
  .payment-card {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 18.4375em;
    height: 11.5625em;
    overflow: hidden;
    padding: 3.75em 1.125em 0.625em 1.125em;
    text-transform: uppercase;
    background: linear-gradient(135deg, #30cbf8, #9dc29d);
    box-shadow: 0 0 35px 14px rgba(0, 0, 0, 0.3);
    border-radius: 0.625em;
    backface-visibility: hidden; }
    .payment-card--front {
      transform: rotateY(0deg); }
    .payment-card--back {
      transform: rotateY(180deg); }
    .payment-card--flip {
      position: relative;
      transform-style: preserve-3d;
      transition: transform 0.6s ease-in; }
    .payment-card--wrap {
      width: 18.4375em;
      height: 11.5625em;
      margin: 1.875em auto;
      perspective: 1000;
      -webkit-perspective: 1000; }
      .payment-card--wrap.rotate .payment-card--flip {
        transform: rotateY(180deg); }
      @media screen and (max-width: 767px) {
        .payment-card--wrap {
          display: none; } }
    .payment-card:before {
      content: '';
      width: 700px;
      height: 700px;
      position: absolute;
      top: -15px;
      right: -50px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      box-shadow: 0 0 0 200px rgba(0, 0, 0, 0.04), inset 0 0 0 120px rgba(0, 0, 0, 0.02); }
    .payment-card:after {
      content: '';
      width: 700px;
      height: 700px;
      position: absolute;
      top: 0;
      left: -60px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      box-shadow: 0 0 0 200px rgba(0, 0, 0, 0.04), inset 0 0 0 120px rgba(0, 0, 0, 0.02); }
    .payment-card--data {
      position: relative;
      z-index: 1; }
    .payment-card--number {
      display: block;
      height: 1.2em;
      line-height: 1.2em;
      font-size: 1.5625em;
      margin-bottom: 0.6em; }
    .payment-card--year, .payment-card--month {
      font-size: 1.125em; }
    .payment-card--name {
      display: block;
      margin-top: 0.625em;
      font-size: 0.875em; }
    .payment-card--type {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 3.75em;
      height: 2.5em;
      background: transparent no-repeat center  / cover;
      opacity: 0;
      transition: opacity 0.3s ease-in; }
      .payment-card--type[data-type="visa"] {
        background-image: url("../img/cards/visa.svg");
        opacity: 1; }
      .payment-card--type[data-type="amex"] {
        background-image: url("../img/cards/amex.svg");
        opacity: 1; }
      .payment-card--type[data-type="mastercard"] {
        background-image: url("../img/cards/mastercard.svg");
        opacity: 1; }
      .payment-card--type[data-type="maestro"] {
        background-image: url("../img/cards/maestro.svg");
        opacity: 1; }
    .payment-card--strip {
      z-index: 10;
      position: absolute;
      top: 2.1875em;
      right: 0;
      left: 0;
      height: 2.5em;
      background: linear-gradient(135deg, #404040, #1a1a1a); }
    .payment-card--ccv {
      z-index: 10;
      position: absolute;
      top: 6.25em;
      right: 5%;
      left: 5%;
      height: 2em;
      line-height: 2em;
      padding: 0 0.625em;
      text-align: right;
      border-radius: 0.625em;
      background: #ffffff;
      color: #1a2451; }
      .payment-card--ccv:before {
        content: 'cvv';
        position: absolute;
        top: -1.375em;
        right: 0.625em;
        line-height: 1;
        text-transform: none;
        color: #ffffff; }
  .payment-info {
    margin: 0 auto;
    padding: 0.3125em 0.75em;
    font-family: "LatoLight", sans-serif;
    border: 1px solid #ffffff;
    background-color: #3c8dbc;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .payment-info {
        position: absolute;
        right: 2.5em;
        bottom: 1.875em;
        left: 2.5em;
        margin: 0;
        width: auto; } }
    @media screen and (max-width: 499px) {
      .payment-info {
        right: 0.9375em;
        left: 0.9375em; } }
    @media screen and (max-width: 399px) {
      .payment-info {
        padding: 1.0625em 0.4375em 0.9375em; } }
    .payment-info img {
      width: 45%; }
      @media screen and (max-width: 767px) {
        .payment-info img {
          width: 35%; } }
    .payment-info p {
      margin: 0;
      padding-bottom: 0.5em;
      font-size: 16px;
      line-height: 1.2; }
      .payment-info p b {
        font-family: "LatoBold", sans-serif; }
  .payment aside {
    width: 3.125em;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #d8dbdb; }
    @media screen and (max-width: 767px) {
      .payment aside {
        position: fixed;
        top: 0;
        left: 0; } }
    .payment aside ul li {
      height: 3.625em;
      border-bottom: 1px solid #acb0b3; }
      .payment aside ul li a {
        display: block;
        padding: 1.125em 0.75em 0.75em 0.75em; }
        .payment aside ul li a svg {
          width: 100%;
          height: 100%; }
          .payment aside ul li a svg path {
            fill: transparent;
            stroke: #acb0b3;
            transition: stroke 0.3s ease-in; }
        .payment aside ul li a:hover svg path {
          stroke: #191e26; }
  .payment-logo {
    display: block;
    height: 3.125em;
    background-color: #ffffff;
    padding: 0.1875em; }
    .payment-logo svg {
      width: 100%;
      height: 100%; }
  .payment-additional {
    display: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    background-color: #1a2451; }
    .payment-additional:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle; }
    .payment-additional:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url("../img/bg-right.jpg") no-repeat center/cover;
      filter: blur(4px);
      transform: scale(1.04);
      box-shadow: inset 10px 5px 40px 10px rgba(0, 0, 0, 0.35);
      opacity: 0.4; }
    @media screen and (min-width: 1280px) {
      .payment-additional {
        display: block;
        float: right;
        height: 100vh;
        width: 25%; } }
    .payment-additional--info {
      position: relative;
      z-index: 1;
      display: inline-block;
      vertical-align: middle; }
      .payment-additional--info h2 {
        padding-bottom: 0.3125em; }
      .payment-additional--info .payment-value {
        position: relative;
        font-size: 3.125em;
        font-family: "LatoLight", sans-serif; }
        .payment-additional--info .payment-value input {
          text-align: center;
          display: inline-block;
          font-size: inherit;
          font-family: inherit;
          color: #ffffff;
          background-color: transparent;
          border: none;
          outline: none; }
        .payment-additional--info .payment-value:before {
          content: '$';
          position: absolute;
          top: 0;
          left: -0.6em; }
          .euro .payment-additional--info .payment-value:before {
            content: '€'; }
          .gbp .payment-additional--info .payment-value:before {
            content: '\00A3'; }
    .payment-additional .back {
      display: inline-block;
      vertical-align: middle;
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 1.25em;
      left: 0;
      font-family: "LatoLight", sans-serif; }
      .payment-additional .back svg {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.5em;
        width: 0.8125em;
        height: 1em; }
        .payment-additional .back svg path {
          fill: #30cbf8;
          transition: all 0.3s ease-in; }
      .payment-additional .back:hover svg path {
        fill: #d8dbdb; }

.footer-text {
  text-align: center;
  color: #ccc;
  font-size: 14px; }

#sidebarAmount {
  width: 150px; }

@media screen and (max-width: 539px) {
  .trust {
    display: none; } }

@keyframes expand {
  0% {
    transform: scale3d(1, 0, 1); }
  25% {
    transform: scale3d(1, 1.2, 1); }
  50% {
    transform: scale3d(1, 0.85, 1); }
  75% {
    transform: scale3d(1, 1.05, 1); }
  100% {
    transform: scale3d(1, 1, 1); } }

.customer-info.disable {
  position: relative; }
  .customer-info.disable:after {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(92, 102, 113, 0.8) url("../img/loader.svg") no-repeat center/contain;
    z-index: 5; }

form {
  position: relative;
  padding: 1.5625em 0em 3.4375em 2.5em; }
  form:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (max-width: 767px) {
    form {
      padding-bottom: 15em; } }
  @media screen and (max-width: 499px) {
    form {
      padding-left: 0.9375em; } }
  form .hidden {
    display: none !important; }
  form h2 {
    padding-bottom: 0.57692em; }
  form .payment-title {
    position: absolute;
    top: -1.68em;
    left: 1.6em;
    font-size: 1.5625em;
    font-family: "LatoLight", sans-serif;
    line-height: 1;
    padding-right: 2.5rem;
    transition: all 0.3s ease-in; }
    @media screen and (max-width: 499px) {
      form .payment-title {
        padding-right: 0.6em;
        left: 0.6em; } }
  form .col-1-2 {
    float: left;
    width: 50%;
    padding-right: 5em; }
    @media screen and (max-width: 1279px) {
      form .col-1-2 {
        padding-right: 3.75em; } }
    @media screen and (max-width: 767px) {
      form .col-1-2 {
        width: 100%; } }
    @media screen and (max-width: 499px) {
      form .col-1-2 {
        padding-right: 0.9375em; } }
  form .payment-input {
    position: relative; }
    form .payment-input--wrap:after {
      content: "";
      display: table;
      clear: both; }
    @media screen and (min-width: 500px) {
      form .payment-input.half {
        float: left;
        width: 49%;
        margin-right: 2%; }
        form .payment-input.half:last-child {
          margin-right: 0; }
        form .payment-input.half span {
          bottom: 1.875em; }
        form .payment-input.half input:focus + span {
          bottom: 0.5em !important; } }
    form .payment-input span {
      position: absolute;
      bottom: 0;
      left: 0;
      font-family: "LatoLight", sans-serif;
      font-size: 0.9375em;
      color: #30cbf8;
      transition: bottom 0.3s ease-in; }
    form .payment-input i {
      z-index: 1;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 0em 0.25em 0.25em 0.25em;
      font-style: normal;
      font-family: "LatoLight", sans-serif;
      background: rgba(48, 203, 248, 0.7);
      border-radius: 0.375em;
      transition: all 0.3s ease-in; }
  form input:-webkit-autofill {
    background-color: #1a2451 !important;
    color: inherit !important; }
  form input:not([type="submit"]):not(.payment-value), form .cs-placeholder {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: 2.10526em;
    font-size: 1.1875em;
    font-family: "LatoLight", sans-serif;
    margin-bottom: 1.57895em;
    background-color: #1a2451;
    border: none;
    border-bottom: 1px solid #d8dbdb;
    outline: none;
    color: inherit; }
    form input:not([type="submit"]):not(.payment-value).card-name, form .cs-placeholder.card-name {
      text-transform: uppercase; }
    form input:not([type="submit"]):not(.payment-value).error, form .cs-placeholder.error {
      border-color: #c50000 !important;
      background-image: url("../img/err_sig.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 30px 30px; }
      form input:not([type="submit"]):not(.payment-value).error::-webkit-input-placeholder, form .cs-placeholder.error::-webkit-input-placeholder {
        color: rgba(197, 0, 0, 0.85); }
      form input:not([type="submit"]):not(.payment-value).error::-moz-placeholder, form .cs-placeholder.error::-moz-placeholder {
        color: rgba(197, 0, 0, 0.85); }
      form input:not([type="submit"]):not(.payment-value).error:-moz-placeholder, form .cs-placeholder.error:-moz-placeholder {
        color: rgba(197, 0, 0, 0.85); }
      form input:not([type="submit"]):not(.payment-value).error:-ms-input-placeholder, form .cs-placeholder.error:-ms-input-placeholder {
        color: rgba(197, 0, 0, 0.85); }
    @media screen and (max-width: 1279px) {
      form input:not([type="submit"]):not(.payment-value), form .cs-placeholder {
        height: 1.73684em;
        line-height: 1.73684em; } }
    form input:not([type="submit"]):not(.payment-value)::-webkit-input-placeholder, form .cs-placeholder::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.27);
      transition: color 0.3s ease-in;
      text-transform: none; }
    form input:not([type="submit"]):not(.payment-value)::-moz-placeholder, form .cs-placeholder::-moz-placeholder {
      color: rgba(255, 255, 255, 0.27);
      transition: color 0.3s ease-in;
      text-transform: none; }
    form input:not([type="submit"]):not(.payment-value):-moz-placeholder, form .cs-placeholder:-moz-placeholder {
      color: rgba(255, 255, 255, 0.27);
      transition: color 0.3s ease-in;
      text-transform: none; }
    form input:not([type="submit"]):not(.payment-value):-ms-input-placeholder, form .cs-placeholder:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.27);
      transition: color 0.3s ease-in;
      text-transform: none; }
    form input:not([type="submit"]):not(.payment-value).valid, form .cs-placeholder.valid {
      border-color: #00a651 !important; }
    form input:not([type="submit"]):not(.payment-value):focus, form .cs-placeholder:focus {
      border-color: #30cbf8; }
      form input:not([type="submit"]):not(.payment-value):focus::-webkit-input-placeholder, form .cs-placeholder:focus::-webkit-input-placeholder {
        color: transparent; }
      form input:not([type="submit"]):not(.payment-value):focus::-moz-pltransparentder, form .cs-placeholder:focus::-moz-pltransparentder {
        color: transparent; }
      form input:not([type="submit"]):not(.payment-value):focus:-moz-placeholder, form .cs-placeholder:focus:-moz-placeholder {
        color: transparent; }
      form input:not([type="submit"]):not(.payment-value):focus:-ms-input-placeholder, form .cs-placeholder:focus:-ms-input-placeholder {
        color: transparent; }
      form input:not([type="submit"]):not(.payment-value):focus + span, form .cs-placeholder:focus + span {
        bottom: -1.5em; }
      form input:not([type="submit"]):not(.payment-value):focus ~ i, form .cs-placeholder:focus ~ i {
        opacity: 1;
        top: -1.5625em; }
  form input[type="submit"] {
    display: inline-block;
    vertical-align: top;
    padding: 0 0.46875em;
    width: 9.8125em;
    height: 1.25em;
    font-family: "LatoLight", sans-serif;
    font-size: 2.625em;
    margin: 0 auto;
    display: block;
    text-align: center;
    color: rgba(255, 255, 255, 0.74);
    background-color: #00a651;
    border-radius: 10px;
    box-shadow: 0 0 1.09375em 0 rgba(0, 0, 0, 0.39);
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease-in; }
    form input[type="submit"]:hover {
      background-color: #3c8dbc; }
    form input[type="submit"][disabled] {
      cursor: auto;
      color: transparent;
      background: #3c8dbc url("../img/loader.svg") no-repeat center/contain; }
    @media screen and (max-width: 1279px) {
      form input[type="submit"] {
        width: auto;
        height: 1.32em;
        line-height: 1.32em;
        font-size: 1.5625em;
        display: inline; } }
  form .payment-value {
    display: none;
    position: relative;
    width: 4.5em;
    height: 1.65em;
    margin-left: 0.6em;
    font-size: 1.25em;
    font-family: "LatoLight", sans-serif;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 0.5em;
    box-shadow: 0 0 1.09375em 0 rgba(0, 0, 0, 0.39); }
    form .payment-value input {
      width: 100%;
      height: 100% !important;
      padding: 0 0.25em 0 1em;
      background-color: transparent !important;
      border: 0 !important;
      margin: 0 !important;
      line-height: normal !important;
      outline: none; }
    form .payment-value:before {
      content: '$';
      position: absolute;
      top: 0.15em;
      left: 0.3em; }
      .euro form .payment-value:before {
        content: '€'; }
      .gbp form .payment-value:before {
        content: '\00A3'; }
    @media screen and (max-width: 1279px) {
      form .payment-value {
        display: inline-block;
        vertical-align: top; } }
  form select {
    display: none;
    background-color: transparent;
    color: inherit; }
  form .payment-select:after {
    content: "";
    display: table;
    clear: both; }
  form .payment-select--left {
    float: left;
    width: 45%; }
  form .payment-select--right {
    float: right;
    width: 45%; }
  form .payment-select--left > div, form .payment-select--right > div {
    position: relative;
    cursor: pointer;
    outline: none; }
  form .payment-select--both {
    float: left;
    width: 100%; }
  form .payment-select--both > div {
    position: relative;
    cursor: pointer;
    outline: none; }
  form .payment-select .cs-placeholder {
    font-family: "LatoLight", sans-serif;
    line-height: 2.10526em;
    color: rgba(255, 255, 255, 0.27); }
    form .payment-select .cs-placeholder.error {
      color: #c50000; }
    form .payment-select .cs-placeholder.cs-selected {
      color: #ffffff; }
  form .payment-select .cs-active .cs-placeholder {
    color: rgba(255, 255, 255, 0.27); }
  form .payment-select .cs-options {
    z-index: 3;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    visibility: visible;
    overflow: visible;
    pointer-events: none;
    transform-origin: 50% 0;
    transform: scale3d(1, 0, 1);
    transition: transform 0.3s ease-in; }
    form .payment-select .cs-options ul {
      height: 18.75em;
      overflow: scroll;
      padding: 0.3125em 0em 0em 0.3125em;
      background-color: #1a2451;
      box-shadow: 0 0 0.9375em rgba(0, 0, 0, 0.3); }
      form .payment-select .cs-options ul li {
        line-height: 1.8;
        cursor: pointer;
        font-family: "LatoLight", sans-serif; }
  form .payment-select .cs-active .cs-placeholder {
    border-color: #30cbf8; }
  form .payment-select .cs-active .cs-options {
    pointer-events: auto;
    transform: scale3d(1, 1, 1); }

.payment-iframe {
  display: none; }

.payment-iframe.active {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px 10px rgba(25, 30, 38, 0.5);
  display: block;
  margin: auto;
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 80%; }
  .payment-iframe.active iframe {
    border: none;
    width: 100%;
    height: 100%; }

body {
  background-color: #212121 !important;
  overflow: hidden; }
  body .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    body .loader .bar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #F5F5F5;
      width: 100px;
      height: 10px;
      border: 2px solid #F5F5F5;
      border-radius: 2px; }
      body .loader .bar .loaded {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #607D8B;
        height: 100%;
        max-width: 100%;
        width: 0%;
        border-radius: 2px;
        transition: width 1s linear; }
    body .loader .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 200px;
      animation: bar 8s linear infinite;
      transition: height 1s ease-in; }
      body .loader .spinner:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
        height: 4px;
        border-radius: 100px;
        background-color: #607D8B; }

@keyframes bar {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }
