.payment-iframe {
  display: none; }
.payment-iframe.active {
  background-color: rgba($white, .9);
  box-shadow:  0 0 15px 10px rgba($black, 0.5);
  display: block;
  margin: auto;
  z-index: 11;
  @include scut-fixed(0 0 0 0);
  @include scut-size(80% 80%);

  iframe {
    border: none;
    @include scut-size(100% 100%); } }
