// Grid settings
$xxs: 400px;
$xs:  500px;
$sm:  768px;
$md:  1024px;
$lg:  1280px;

// Colors
$black:      #191e26;
$white:      #ffffff;
$dark:       #1a2451;
$dark1:      #1a2451;
$dark2:      #48535d;
$gray:       #d8dbdb;
$gray1:      #acb0b2;
$gray2:      #3c8dbc;
$gray3:      #acb0b3;
$blue:       #30cbf8;
$green:      #00a651;
$purple:     #c562ac;
$error:      #c50000;


// Fonts
$font-r: 'Lato', sans-serif;
$font-l: 'LatoLight', sans-serif;
$font-b: 'LatoBold', sans-serif;

// Transition time
$hoverTime: 0.3s;






