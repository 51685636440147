.payment {
  @include scut-clearfix;
  overflow: hidden;
  font-family: $font-r;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  color: $white;

  @include media-below($xxs) {
    font-size: 14px; }

  h2 {
    font-family: $font-l;
    font-size: scut-em(31);

    @include media-below($lg) {
      font-size: scut-em(26); } }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none; } }

  a {
    text-decoration: none;
    color: inherit;
    outline: none; }

  input {
    &::-webkit-input-placeholder {
      color: $white;
      font-family: $font-l; }
    &::-moz-placeholder {
      color: $white;
      font-family: $font-l; }
    &:-moz-placeholder {
      color: $white;
      font-family: $font-l; }
    &:-ms-input-placeholder {
      color: $white;
      font-family: $font-l; } }

  &-main {
    position: relative;
    height: 100vh;
    padding-left: scut-em(50);
    background-color: $dark;
    overflow: auto;


    @include media-above($lg) {
      width: 75%;
      float: left; }

    @include media-below($sm) {
      //height: auto
      padding-top: scut-em(50); }

    @include media-below($xs) {
      padding-top: scut-em(100); } }

  &-menu {
    position: relative;
    height: scut-em(50);
    //padding-right: scut-em(120)
    border-bottom: 1px solid $dark2;

    @include media-below($sm) {
      z-index: 3;
      @include scut-fixed(0 0 n scut-em(50));
      background: $dark; }


    &--master {
      width: scut-em(60);
      @include scut-absolute(scut-em(8) scut-em(10) 0 n); }
    //+scut-absolute(0 scut-em(110) 0 n)
    //background: url('../img/ssl.svg') no-repeat center / contain


    &--dot1 {
      width: scut-em(10);
      height: scut-em(10);
      @include scut-absolute(scut-em(20) scut-em(85) 0 n);
      background: #ccc;
      border-radius: 50px;
      @include media-below($sm) {
        @include scut-absolute(scut-em(20) scut-em(75) 0 n); } }

    &--visa {
      width: scut-em(60);
      @include scut-absolute(scut-em(3) scut-em(110) 0 n);
      @include media-below($sm) {
        @include scut-absolute(scut-em(3) scut-em(90) 0 n); } }

    &--dot2 {
      width: scut-em(10);
      height: scut-em(10);
      @include scut-absolute(scut-em(20) scut-em(185) 0 n);
      background: #ccc;
      border-radius: 50px;
      @include media-below($sm) {
        @include scut-absolute(scut-em(20) scut-em(155) 0 n); } }

    &--ssl {
      width: scut-em(60);
      @include scut-absolute(scut-em(10) scut-em(210) 0 n);
      height: 40px;
      @include media-below($sm) {
        @include scut-absolute(scut-em(10) scut-em(170) 0 n); }

      @include media-below($sm) {
        background-size: 80%; } }

    button {
      @include scut-absolute(n 20% scut-em(-20, 14) n);
      font-size: scut-em(13);
      padding: scut-em(9, 13) scut-em(20, 13);
      text-transform: uppercase;
      background-color: $blue;
      color: $black;
      border-radius: scut-em(40, 13);
      box-shadow: 0 0 scut-em(35, 13) 0 rgba(0,0,0,.39);
      outline: none;
      transition: all $hoverTime ease-in;
      &:hover, &:focus {
        background-color: $gray2;
        color: $white; }

      @include media-below($lg) {
        right: 10%; }

      @include media-below($sm) {
        position: fixed;
        right: auto;
        left: scut-em(12, 13);
        bottom: scut-em(8, 13);
        padding: 0;
        text-indent: -9000px;
        @include scut-size(scut-em(34));
        border-radius: 50%;
        &:before {
          content: '';
          @include scut-absolute(scut-em(9px) scut-em(16px) n n);
          @include scut-size(scut-em(2px 16px));
          background: rgba($white,0.7); }
        &:after {
          content: '';
          @include scut-absolute(scut-em(16px) scut-em(9px) n n);
          @include scut-size(scut-em(16px 2px));
          background: rgba($white,0.7); } } }

    ul {
      li {
        display: inline-block;
        border-right: 1px solid $dark2;
        text-transform: uppercase;
        float: left;
        .lang_sel {
          padding-top: 10px;
          line-height: 30px;
          img {
            width: 30px; } } }
      a {
        display: block;
        padding: 0 scut-em(25, 14);
        font-size: scut-em(14);
        line-height: scut-em(50, 14);
        @include media-below($sm) {
          font-size: scut-em(10);
          padding: 0 scut-em(6, 6);
          line-height: scut-em(50, 10); } } }


    @include media-below($sm) {
      &--wrap {
        position: relative; }

      ul {
        white-space: nowrap;
        height: 50px; } } }

  &-messages {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-height: scut-em(46, 19);
    padding: scut-em(10, 19) scut-em(15, 19);
    margin: scut-em(18, 19) scut-em(80, 19) 0 scut-em(40, 19);
    font-size: scut-em(19);
    font-family: $font-l;
    line-height: 1.2;
    border-radius: scut-em(30, 19);
    border: 2px solid transparent;
    &.error {
      background-color: $gray2;
      border-color: $purple; }
    &.success {
        background-color: $gray2;
        border-color: $green; }

    @include media-below($lg) {
      padding: scut-em(8, 19) scut-em(15, 19); }

    @include media-below($xs) {
      margin: scut-em(18, 19) scut-em(15, 19) 0;
      position: fixed;
      bottom: 20px; } }

  &-info {
    @include scut-size(scut-em(295 185));
    box-shadow: 0 0 35px 14px rgba(0,0,0,.3);
    margin: scut-em(30 0 0 10);
    border-radius: scut-em(10); }

  &-card {
    @include scut-absolute(0 0 0 0);
    @include scut-size(scut-em(295 185));
    overflow: hidden;
    padding: scut-em(60 18 10 18);
    text-transform: uppercase;
    background: linear-gradient(135deg, $blue, #9dc29d);
    box-shadow: 0 0 35px 14px rgba(0,0,0,.3);
    border-radius: scut-em(10);
    backface-visibility: hidden;

    &--front {
      transform: rotateY(0deg); }
    &--back {
      transform: rotateY(180deg); }

    &--flip {
      position: relative;
      transform-style: preserve-3d;
      transition: transform 0.6s ease-in; }

    &--wrap {
      @include scut-size(scut-em(295 185));
      margin: scut-em(30) auto;
      perspective: 1000;
      -webkit-perspective: 1000;


      &.rotate .payment-card--flip {
        transform: rotateY(180deg); }

      @include media-below($sm) {
        display: none; } }

    &:before {
      content: '';
      width: 700px;
      height: 700px;
      @include scut-absolute(-15px -50px n n);
      background: rgba(255,255,255,.1);
      border-radius: 50%;
      box-shadow: 0 0 0 200px rgba(0,0,0,.04), inset 0 0 0 120px rgba(0,0,0,.02); }

    &:after {
      content: '';
      width: 700px;
      height: 700px;
      @include scut-absolute(0 n n -60px);
      background: rgba(255,255,255,.1);
      border-radius: 50%;
      box-shadow: 0 0 0 200px rgba(0,0,0,.04), inset 0 0 0 120px rgba(0,0,0,.02); }

    &--data {
      position: relative;
      z-index: 1; }

    &--number {
      display: block;
      height: scut-em(30, 25);
      line-height: scut-em(30, 25);
      font-size: scut-em(25);
      margin-bottom: scut-em(15, 25); }

    &--year, &--month {
      font-size: scut-em(18); }

    &--name {
      display: block;
      margin-top: scut-em(10);
      font-size: scut-em(14); }

    &--type {
      @include scut-absolute(n 0 0 n);
      @include scut-size(scut-em(60 40));
      background: transparent no-repeat center  / cover;
      opacity: 0;
      transition: opacity $hoverTime ease-in;

      &[data-type="visa"] {
        background-image: url('../img/cards/visa.svg');
        opacity: 1; }
      &[data-type="amex"] {
        background-image: url('../img/cards/amex.svg');
        opacity: 1; }
      &[data-type="mastercard"] {
        background-image: url('../img/cards/mastercard.svg');
        opacity: 1; }
      &[data-type="maestro"] {
        background-image: url('../img/cards/maestro.svg');
        opacity: 1; } }

    &--strip {
      z-index: 10;
      @include scut-absolute(scut-em(35) 0 n 0);
      height: scut-em(40);
      background: linear-gradient(135deg, #404040, #1a1a1a); }

    &--ccv {
      z-index: 10;
      @include scut-absolute(scut-em(100) 5% n 5%);
      height: scut-em(32);
      line-height: scut-em(32);
      padding: 0 scut-em(10);
      text-align: right;
      border-radius: scut-em(10);
      background: $white;
      color: $dark;
      &:before {
        content: 'cvv';
        @include scut-absolute(scut-em(-22) scut-em(10) n n);
        line-height: 1;
        text-transform: none;
        color: $white; } } }

  &-info {
    margin: 0 auto;
    padding: scut-em(05 12);
    font-family: $font-l;
    border: 1px solid $white;
    background-color: $gray2;
    text-align: center;

    @include media-below($sm) {
      @include scut-absolute(n scut-em(40) scut-em(30) scut-em(40));
      margin: 0;
      width: auto; }

    @include media-below($xs) {
      right: scut-em(15);
      left: scut-em(15); }
    @include media-below($xxs) {
      padding: scut-em(17 7 15); }

    img {
        width: 45%;
        @include media-below($sm) {
          width: 35%; } }

    p {
      margin: 0;
      padding-bottom: scut-em(8);
      font-size: 16px;
      line-height: 1.2;
      b {
        font-family: $font-b; } } }

  aside {
    @include scut-size(scut-em(50) 100%);
    @include scut-absolute(0 n 0 0);
    background-color: $gray;

    @include media-below($sm) {
      @include scut-fixed(0 n n 0); }

    ul li {
      height: scut-em(58);
      border-bottom: 1px solid $gray3;
      a {
        display: block;
        padding: scut-em(18 12 12 12);
        svg {
          @include scut-size(100%);
          path {
            fill: transparent;
            stroke: $gray3;
            transition: stroke $hoverTime ease-in; } }
        &:hover {
          svg path {
            stroke: $black; } } } } }


  &-logo {
    display: block;
    height: scut-em(50);
    background-color: $white;
    padding: scut-em(3);
    svg {
      @include scut-size(100%); } }

  &-additional {
    display: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    background-color: $dark1;
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle; }

    &:after {
      content: '';
      @include scut-absolute(0 0 0 0);
      background: url('../img/bg-right.jpg') no-repeat center / cover;
      filter: blur(4px);
      transform: scale(1.04);
      box-shadow: inset 10px 5px 40px 10px rgba(0,0,0,.35);
      opacity: 0.4; }

    @include media-above($lg) {
      display: block;
      float: right;
      height: 100vh;
      width: 25%; }

    &--info {
      position: relative;
      z-index: 1;
      display: inline-block;
      vertical-align: middle;

      h2 {
        padding-bottom: scut-em(5, 16); }

      .payment-value {
        position: relative;
        font-size: scut-em(50);
        font-family: $font-l;
        input {
          //width: scut-em(120, 50)
          text-align: center;
          display: inline-block;
          font-size: inherit;
          font-family: inherit;
          color: $white;
          background-color: transparent;
          border: none;
          outline: none; }

        &:before {
          content: '$';
          @include scut-absolute(0 n n scut-em(-30, 50));
          .euro & {
            content: '€'; }
          .gbp & {
            content: '\00A3'; } } } }

    .back {
      display: inline-block;
      vertical-align: middle;
      z-index: 1;
      @include scut-absolute(n 0 scut-em(20) 0);
      font-family: $font-l;
      svg {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: scut-em(8);
        @include scut-size(scut-em(13 16));
        path {
          fill: $blue;
          transition: all $hoverTime ease-in; } }
      &:hover {
        svg path {
          fill: $gray; } } } } }

.footer-text {
  text-align: center;
  color: #ccc;
  font-size: 14px; }


#sidebarAmount {
  width: 150px; }

.trust {
  @include media-below(540) {
    display: none; } }
