$dark: #212121;
$white: #F5F5F5;
$main: #607D8B;
body {
  background-color:$dark !important;
  overflow:hidden;
  .loader {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    //animation: bar 8s linear infinite;
    //Edit suggested by /u/KNetwalker : http://codepen.io/SureID/pen/aNRxdw
    .bar {
      position: absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      background-color:$white;
      width:100px;
      height:10px;
      border:2px solid $white;
      border-radius:2px;
      .loaded {
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        background-color:$main;
        height:100%;
        max-width:100%;
        width:0%;
        border-radius:2px;
        transition: width 1s linear;
      }
    }
    .spinner {
      position: absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      height:200px;
      animation: bar 8s linear infinite;
      transition: height 1s ease-in;
      &:before {
        content: '';
        position: absolute;
        top:0px;
        left:50%;
        transform:translate(-50%,-50%);
        width:4px;
        height:4px;
        border-radius:100px;
        background-color:$main;
      }
    }
  }
}

@keyframes bar {
  0% {
    transform:translate(-50%,-50%) rotate(0deg);
  }
  100% {
    transform:translate(-50%,-50%) rotate(360deg);
  }
}